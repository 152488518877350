import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Router } from '@angular/router';
import { AppRoutes } from '@src/app/shared/constants';
import { StorageService } from '@src/app/shared/services';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable, ignoreElements, map, tap } from 'rxjs';
import { LoginForm } from '../interfaces';
import { User } from '../models';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

interface IRespLogin {
	token: string;
}
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private httpClient = inject(HttpClient);
	private router = inject(Router);
	private storageService = inject(StorageService);
	urlLogin = environment.urlLogin;
	user = signal<User | null>(null);
	isLoggedIn$ = toObservable(this.user).pipe(map(Boolean));

	constructor() {
		this.user.set(this.getUserFromLocalStorage());
	}

	login(form: FormGroup<LoginForm>): Observable<void> {
		const { value } = form;
		const { cardId, password, username } = value;

		return this.httpClient
			.post<IRespLogin | null>(
				`${this.urlLogin}/${AppRoutes.Api}/${AppRoutes.Usuario}/${AppRoutes.OFVPSS}`,

				{
					login: username.trim().toLowerCase(),
					cedula: cardId,
					password,
				}
			)
			.pipe(
				tap((resp) => {
					if (!resp?.token) {
						const errorMessage =
							'Lo siento, la Cédula, el Usuario o la Contraseña son incorrectos. Por favor, revisa la información proporcionada e inténtalo nuevamente.';
						const errorCode = 401;
						throw { code: errorCode, message: errorMessage };
					}

					return this.storageService.writeToken(resp?.token);
				}),
				tap(() => this.user.set(this.getUserFromLocalStorage())),
				tap(() => this.redirectToPrivate()),
				ignoreElements()
			);
	}

	logout(): void {
		this.storageService.removeToken();
		this.user.set(null);
		this.redirectToLogin();
	}

	private getUserFromLocalStorage(): User | null {
		const user = this.storageService.readDecodeToken();

		return user ? new User(user) : null;
	}

	private redirectToLogin(): void {
		this.router.navigateByUrl('/auth');
	}

	private redirectToPrivate(): void {
		this.router.navigateByUrl('/');
	}
}
