import { IUser } from '../interfaces';

export class User {
	public unique_name: string;
	public nombreUsuario: string;
	public prestadoraId: number;
	public usuarioId: number;
	public nombePrestadora: string;
	public jti: string;
	public exp: number;
	public rnc: string;

	constructor({
		exp,
		jti,
		nombePrestadora,
		nombreUsuario,
		prestadoraId,
		unique_name,
		usuarioId,
		rnc,
	}: IUser) {
		this.exp = exp;
		this.jti = jti;
		this.nombePrestadora = nombePrestadora;
		this.nombreUsuario = nombreUsuario;
		this.prestadoraId = prestadoraId;
		this.unique_name = unique_name;
		this.usuarioId = usuarioId;
		this.rnc = rnc;
	}
}
